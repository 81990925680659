<template>
  <div
    class="cursor-pointer"
    v-bind="$attrs"
    @click="navigate"
  >
    <slot v-bind="{to}" />
  </div>
</template>

<script setup lang="ts">
import type {RouteLocationRaw} from 'vue-router';

const props = defineProps<{
  to: RouteLocationRaw
}>();

const router = useRouter();

function navigate(e: MouseEvent) {
  if ((e.target as HTMLElement).closest('a, button') !== null) {
    return;
  }

  if (typeof props.to === 'string' && props.to.startsWith('http')) {
    window.location.href = props.to;
  } else {
    router.push(props.to);
  }
}
</script>
